import React from "react"
import { Link } from "gatsby"
import Img from "gatsby-image"
import styled from "@emotion/styled"
import Time from "../components/time"
import theme from "styled-theming"

const articleKeyboardFocusOutlineColor = theme("mode", {
  light: props => props.theme.colors.darkestPurple,
  dark: props => props.theme.colors.white
})

const BlogLink = styled(Link)`
  && {
    color: ${props => props.theme.colors.white};
    display: block;
    height: 100%;

    &:hover,
    &:active {
      color: ${props => props.theme.colors.white};
    }

    &:focus-visible {
       outline: 2px dotted ${articleKeyboardFocusOutlineColor};
    }
  }
`

const StyledHeader = styled.header`
  h2 {
    margin: 0;
    position: absolute;
    top: 50%;
    left: 0;
    padding: ${props => props.theme.spacers.sm};
    transform: translateY(-50%);
  }
`

const StyledArticle = styled.article`
  height: 250px;
  color: ${props => props.theme.colors.white};
  background-image: linear-gradient(
    60deg,
    rgba(86, 177, 212, 0.6) 0%,
    rgba(132, 116, 144, 0.7) 100%
  );
  background-color: ${props => props.theme.colors.gray};
  position: relative;
  margin-bottom: ${props => props.theme.spacers.sm};
  margin-left: calc(50% - 50vw); // Break out of grid
  margin-right: calc(50% - 50vw); // Break out of grid
  transition: background-image 0.3s;

  &:last-of-type {
    margin-bottom: 0;
  }

  @media (min-width: 700px) {
    height: auto;
    margin: 0;
  }

  .gatsby-image-wrapper {
    opacity: 0.7;
  }

  &:hover {
    background-image: linear-gradient(
      60deg,
      rgba(60, 132, 160, 1) 0%,
      rgba(118, 108, 125, 0.9) 100%
    );

    .gatsby-image-wrapper {
      opacity: 1;
    }
  }
`

const StyledTime = styled(Time)`
  position: absolute;
  left: ${props => props.theme.spacers.sm};
  bottom: 5%;
  color: ${props => props.theme.colors.white};
`

const renderImage = image => {
  if (!image) return null

  return <Img fluid={image.childImageSharp.fluid} />
}

const PostLink = ({ post }) => (
  <StyledArticle>
    <BlogLink className="unstyled" to={post.frontmatter.url}>
      <StyledHeader>
        {renderImage(post.frontmatter.featured_image)}
        <h2>{post.frontmatter.title}</h2>
        <StyledTime>{post.frontmatter.date}</StyledTime>
      </StyledHeader>
    </BlogLink>
  </StyledArticle>
)

export default PostLink
