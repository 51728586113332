import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"
import theme from "styled-theming"

const timeTextColor = theme("mode", {
  light: props => props.theme.colors.mediumGray,
  dark: props => props.theme.colors.lightGray,
})

const StyledTime = styled.time`
  text-transform: uppercase;
  font-size: ${props => props.theme.typography.scale[0]};
  color: ${timeTextColor};
`

const Time = ({ className, children }) => (
  <StyledTime className={className}>{children}</StyledTime>
)

Time.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default Time
