import React from "react"
import PropTypes from "prop-types"
import styled from "@emotion/styled"

const StyledDiv = styled.div`
  padding-top: ${props => props.theme.spacers.md};

  @media (min-width: 700px) {
    display: grid;
    padding-top: ${props => props.theme.spacers.lg};
    grid-gap: ${props => props.theme.spacers.lg};
    grid-template-columns: repeat(2, 1fr);
    grid-auto-rows: 350px;
  }

  @media (min-width: 1000px) {
    grid-template-columns: repeat(3, 1fr);
  }

  .gatsby-image-wrapper {
    width: 100%;
    height: 250px;
    object-fit: cover;

    @media (min-width: 700px) {
      height: 350px;
    }
  }
`

const PostListContainer = ({ className, children }) => (
  <StyledDiv className={className}>{children}</StyledDiv>
)

PostListContainer.propTypes = {
  className: PropTypes.string,
  children: PropTypes.node.isRequired,
}

export default PostListContainer
